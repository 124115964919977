import { State } from "@ts/States/State";
import Power from "@vue/Power.vue";
export class StatePower extends State {
    constructor(time) {
        super(Power, time);
    }
    data() {
        return {};
    }
}
