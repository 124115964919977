var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from "vue-property-decorator";
import { Runner } from "@ts/App/Runner";
import { StateFactory } from "@ts/Factories/StateFactory";
import Power from "@vue/Power.vue";
let Screen = class Screen extends Vue {
    constructor(props) {
        super(props);
        this.component = Power;
        this.componentData = null;
        this.keypress = null;
        this.width = "800px";
        this.height = "600px";
        this.runner = new Runner(this);
    }
    created() {
        let self = this;
        window.addEventListener('keydown', (e) => {
            self.keypress = e.key;
            if (self.component == Power) {
                self.cmdPower();
                self.keypress = null;
            }
        });
    }
    cmdPower() {
        console.log("Let's go!");
        console.log("Try out the BIOS hotkeys while you're at it!");
        this.runner.add(StateFactory.createBios());
        this.runner.start();
    }
    cmdSkip() {
        console.log("Skipping...");
        this.runner.add(StateFactory.createFast());
        this.runner.start();
    }
    cmdReboot() {
        console.log("Rebooting...");
        this.runner.add(StateFactory.createReboot());
        this.runner.add(StateFactory.createBios());
        this.runner.start();
    }
    cmdShutdown() {
        console.log("Goodbye!");
        this.runner.add(StateFactory.createShutdown());
        this.runner.start();
    }
    getKeypress() {
        return this.keypress;
    }
    resetKeypress() {
        this.keypress = null;
    }
    setComponentData(data) {
        data.keypress = this.keypress;
        this.componentData = data;
    }
    setWidth(width) {
        this.width = width;
    }
    setHeight(height) {
        this.height = height;
    }
    setComponent(component) {
        this.component = component;
    }
};
Screen = __decorate([
    Component
], Screen);
export default Screen;
