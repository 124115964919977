import { State } from "@ts/States/State";
import Console from "@vue/Console.vue";
export class StateModeColor extends State {
    constructor(time, con, grey) {
        super(Console, time);
        this.con = con;
        this.grey = grey;
    }
    data() {
        this.con.grey = this.grey;
        return this.con;
    }
}
