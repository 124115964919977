import { State } from "@ts/States/State";
import Blank from "@vue/Blank.vue";
export class StateBlank extends State {
    constructor(time, color = "#000", image) {
        super(Blank, time);
        this.color = color;
        this.image = image;
    }
    data() {
        let image = "";
        if (this.image) {
            image = "url('/img/" + this.image + "')";
        }
        return {
            color: this.color,
            image: image
        };
    }
}
