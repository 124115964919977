var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
let Bios = class Bios extends Vue {
    get help() {
        let self = this;
        let text = "Press Enter to enter SETUP, Shift for Network Boot, ESC for Boot Menu";
        if (self.keypress == "Shift")
            text = "Starting PXE boot...";
        if (self.keypress == "Escape")
            text = "Not yet implemented!";
        if (self.keypress == "Enter")
            text = "Not yet implemented!";
        return text;
    }
};
__decorate([
    Prop()
], Bios.prototype, "code", void 0);
__decorate([
    Prop()
], Bios.prototype, "percent", void 0);
__decorate([
    Prop()
], Bios.prototype, "keypress", void 0);
Bios = __decorate([
    Component
], Bios);
export default Bios;
