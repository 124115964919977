import { render, staticRenderFns } from "./Bios.vue?vue&type=template&id=ba070f46&scoped=true&"
import script from "./Bios.vue?vue&type=script&lang=ts&"
export * from "./Bios.vue?vue&type=script&lang=ts&"
import style0 from "./Bios.vue?vue&type=style&index=0&id=ba070f46&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba070f46",
  null
  
)

export default component.exports