import { State } from "@ts/States/State";
import Boot from "@vue/Bios.vue";
export class StateBios extends State {
    constructor(time, percent, code) {
        super(Boot, time);
        this.percent = percent;
        this.code = code;
    }
    data() {
        return {
            code: this.code,
            percent: this.percent + "%"
        };
    }
}
