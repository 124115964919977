var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { AppFactory } from "@ts/Factories/AppFactory";
import Window from "@vue/Window.vue";
let Desktop = class Desktop extends Vue {
    constructor() {
        super(...arguments);
        this.min = false;
        this.app = null;
        this.appLast = null;
        this.apps = AppFactory.createApps();
    }
    mounted() {
        let self = this;
        setTimeout(function () {
            self.cmdLaunch(self.apps[1], true);
        }, 200);
    }
    cmdMin(min) {
        this.min = min;
    }
    cmdClose() {
        if (this.appLast?.componentData) {
            this.cmdLaunch(this.appLast, true);
        }
        else {
            this.app = null;
        }
        this.appLast = null;
    }
    cmdLaunch(app, isLaunchedFromDesktop) {
        this.appLast = this.app;
        if (isLaunchedFromDesktop) {
            // If we launch from the desktop clear the last app so
            // the explorer window doesn't re-open
            this.appLast = null;
        }
        this.min = false;
        this.app = app;
        // Tell window we're opening up
        let self = this;
        this.$nextTick(function () {
            self.window.onOpen();
        });
    }
    // Pass shutdown and reboot events to Screen
    cmdShutdown() { }
    cmdReboot() { }
};
__decorate([
    Prop()
], Desktop.prototype, "color", void 0);
__decorate([
    Prop()
], Desktop.prototype, "image", void 0);
__decorate([
    Ref()
], Desktop.prototype, "window", void 0);
__decorate([
    Emit()
], Desktop.prototype, "cmdShutdown", null);
__decorate([
    Emit()
], Desktop.prototype, "cmdReboot", null);
Desktop = __decorate([
    Component({
        components: { Window }
    })
], Desktop);
export default Desktop;
