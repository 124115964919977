import { State } from "@ts/States/State";
export class StateResize extends State {
    constructor(time, width = null, height = null) {
        super(null, time);
        this.width = width;
        this.height = height;
    }
    data(runner) {
        runner.getScreen().setWidth(this.width);
        runner.getScreen().setHeight(this.height);
    }
}
