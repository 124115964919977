var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Emit, Vue } from "vue-property-decorator";
let Reboot = class Reboot extends Vue {
    cmdReboot() { }
    cmdShutdown() { }
    cmdClose() { }
};
__decorate([
    Emit()
], Reboot.prototype, "cmdReboot", null);
__decorate([
    Emit()
], Reboot.prototype, "cmdShutdown", null);
__decorate([
    Emit()
], Reboot.prototype, "cmdClose", null);
Reboot = __decorate([
    Component
], Reboot);
export default Reboot;
