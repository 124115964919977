import { State } from "@ts/States/State";
export class StateKey extends State {
    constructor(time, choices) {
        super(null, time);
        this.choices = choices;
    }
    data(runner) {
        if (this.choices.length == 0) {
            runner.getScreen().resetKeypress();
            return null;
        }
        for (let i = 0; i < this.choices.length; i++) {
            if (runner.getScreen().getKeypress() == this.choices[i].keypress) {
                runner.add(this.choices[i].events);
                runner.getScreen().resetKeypress();
                return;
            }
        }
        // Run default first
        runner.add(this.choices[0].events);
        runner.getScreen().resetKeypress();
    }
}
