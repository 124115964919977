var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
let Console = class Console extends Vue {
    constructor() {
        super(...arguments);
        this.off = false;
    }
    created() {
        let self = this;
        // Blink cursor
        setInterval(function () {
            self.off = !self.off;
        }.bind(this), 250);
    }
    updated() {
        let self = this;
        // Auto scroll down
        self.$nextTick(function () {
            let objDiv = document.getElementById("screen");
            if (objDiv)
                objDiv.scrollTop = objDiv.scrollHeight;
        });
    }
};
__decorate([
    Prop()
], Console.prototype, "lines", void 0);
__decorate([
    Prop()
], Console.prototype, "grey", void 0);
Console = __decorate([
    Component
], Console);
export default Console;
