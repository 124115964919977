export class ConData {
    constructor() {
        this.timeStart = 0;
        this.lines = [];
        this.grey = false;
    }
    addLine(format, line) {
        // Return early if the line is empty
        if (!line) {
            return;
        }
        // Elapsed time
        let elapsed = 0;
        if (this.timeStart != 0) {
            elapsed = (new Date().getTime() - this.timeStart) / 1000 + Math.random() / 1000;
        }
        // Apply formatting
        switch (format) {
            case Format.Kernel:
                this.lines.push(this.formatTime(elapsed) + line);
                break;
            case Format.ServiceFinishOK:
                this.lines.push(this.formatOK(line, "Finished"));
                break;
            case Format.ServiceStartOK:
                this.lines.push(this.formatOK(line, "Started"));
                break;
            case Format.ServiceTarget:
                this.lines.push(this.formatOK(line, "Reached Target"));
                break;
            case Format.ServiceStarting:
                this.lines.push(this.formatStarting(line));
                break;
            case Format.None:
            default:
                this.lines.push(line);
        }
        // Update the start time if it is unset
        if (this.timeStart == 0) {
            this.timeStart = new Date().getTime();
        }
    }
    formatStarting(line) {
        // why write code when you can write hard code
        return "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Starting <span class='white'>" + line + "...</span>";
    }
    formatOK(line, state) {
        return "[&nbsp;&nbsp;<span class='green'>OK</span>&nbsp;&nbsp;] " + state + " <span class='white'>" + line + ".</span>";
    }
    formatTime(number) {
        // Format time [   0.000000] like that
        const len = 11;
        const frac = 6;
        const n = number.toFixed(frac) + '';
        const time = n.length >= len ? n : new Array(len - n.length + 1).join('&nbsp;') + n;
        return '[' + time + '] ';
    }
}
export var Format;
(function (Format) {
    Format[Format["None"] = 0] = "None";
    Format[Format["Kernel"] = 1] = "Kernel";
    Format[Format["ServiceStartOK"] = 2] = "ServiceStartOK";
    Format[Format["ServiceFinishOK"] = 3] = "ServiceFinishOK";
    Format[Format["ServiceStarting"] = 4] = "ServiceStarting";
    Format[Format["ServiceTarget"] = 5] = "ServiceTarget";
})(Format || (Format = {}));
