var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
let Window = class Window extends Vue {
    constructor() {
        super(...arguments);
        this.min = false;
        this.max = false;
    }
    get style() {
        let style = {};
        if (!this.max) {
            style.maxWidth = this.app.width + "px";
            if (this.app.height == 0) {
                style.height = "auto";
            }
            else {
                style.maxHeight = this.app.height + "px";
            }
        }
        return style;
    }
    onOpen() {
        if (!this.app.maximizable) {
            this.max = false;
        }
        else {
            this.max = window.innerWidth < this.app.width || this.max;
        }
        this.min = false;
    }
    // Let window manager know we minimized.
    cmdMin() {
        this.min = !this.min;
        return this.min;
    }
    cmdMax() {
        if (this.min) {
            this.min = false;
            this.$emit('cmd-min', false);
        }
        else if (this.app?.maximizable) {
            this.max = !this.max;
        }
    }
    // Pass Close, Reboot, Shutdown, and Launch to Window
    cmdClose() { }
    cmdReboot() { }
    cmdShutdown() { }
    cmdLaunch(app) {
        return app;
    }
};
__decorate([
    Prop()
], Window.prototype, "app", void 0);
__decorate([
    Emit()
], Window.prototype, "cmdMin", null);
__decorate([
    Emit()
], Window.prototype, "cmdClose", null);
__decorate([
    Emit()
], Window.prototype, "cmdReboot", null);
__decorate([
    Emit()
], Window.prototype, "cmdShutdown", null);
__decorate([
    Emit()
], Window.prototype, "cmdLaunch", null);
Window = __decorate([
    Component
], Window);
export default Window;
