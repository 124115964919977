import { State } from "@ts/States/State";
import { Format } from "@ts/App/ConData";
import Console from "@vue/Console.vue";
export class StateConsole extends State {
    constructor(time, con, text, format = Format.Kernel) {
        super(Console, time);
        this.con = con;
        this.text = text;
        this.format = format;
    }
    data() {
        this.con.addLine(this.format, this.text);
        return this.con;
    }
}
