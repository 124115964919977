export class Runner {
    constructor(screen) {
        this.states = [];
        this.running = false;
        this.screen = screen;
    }
    add(states) {
        this.states = this.states.concat(states);
    }
    start() {
        if (!this.running) {
            this.running = true;
            this.run();
        }
    }
    getScreen() {
        return this.screen;
    }
    run() {
        let current = this.states.shift();
        if (!current) {
            this.running = false;
            return;
        }
        let data = current.data(this);
        if (data) {
            this.getScreen().setComponentData(data);
        }
        if (current.comp) {
            this.getScreen().setComponent(current.comp);
        }
        setTimeout(this.run.bind(this), current.time);
    }
}
