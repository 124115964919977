export class App {
    constructor(app, title, label, hover, icon, width, height, maximizable = true, background, componentData) {
        this.icon = icon;
        this.app = app;
        this.width = width;
        this.height = height;
        this.maximizable = maximizable;
        this.title = title;
        this.label = label;
        this.hover = hover;
        this.background = background;
        this.componentData = componentData;
    }
}
